import React from "react"
import { Container } from "react-bootstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"
//import Layout from '../components/layout'
import { Helmet } from "react-helmet"

function NotFoundPage() {
  return (
    <div>
      <Container>
        <Helmet title="ISN 404 - Inspection Support Network" defer={false} />
        <Header activepage="| 404 Not found" />
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Container style={{ margin: "0 auto", textAlign: "center" }}>
        <br />
        <br />
        <h1 className="headerreplacement">PAGE NOT FOUND</h1>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h3 className="subhead-larger">
          Perhaps you would like to go back <a href="/">home?</a>
        </h3>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </div>
  )
}

export default NotFoundPage
